import GeoJSON from 'ol/format/GeoJSON.js';
import Map from 'ol/Map.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';
import View from 'ol/View.js';
import { LineString, Point } from 'ol/geom.js';
import Polygon from 'ol/geom/Polygon.js';
import MultiPolygon from 'ol/geom/MultiPolygon.js';
import { getCenter } from 'ol/extent.js';
import Feature from 'ol/Feature.js';
import Cluster from 'ol/source/Cluster.js';
import { Style, Circle as CircleStyle, Fill, Stroke, Text, Icon, RegularShape } from 'ol/style.js';
import Overlay from 'ol/Overlay.js';
import { fromLonLat, toLonLat } from 'ol/proj.js';
import { showPopUp, closePopUp } from '../ui/popup.js';
import { rightPanelSetInfo, closeRightPanel } from '../ui/rightpanel.js';
import { leftPanelSetInfo, closeLeftPanel } from '../ui/leftpanel.js';
import Settlement from './settlements.js';
import Province from './provinces.js';
import Army from './armies.js';
import { panelShowInfo } from '../index.js';

import { supabase } from '../supabaseClient.js';

const rightSlidingPanel = document.getElementById('right-sliding-panel');
const settlementCheckbox = document.getElementById('settlement-checkbox');
const armyCheckbox = document.getElementById('army-checkbox');

export default class MapObj {
  constructor() {
    this.settlements = {};
    this.provinces = {};
    this.armies = {};
    this.highlightedProvinces = [];
    this.armyLayers = [];
    this.RightPanel = '';
    this.firstSettlementPlacementRequired = false;
    this.settlementPlacementMode = false;
    this.cursorImage = null;

	this.movementArrowsLayer = new VectorLayer({
		source: new VectorSource(),
		zIndex: 150,
	});

    this.settlementsLayer = new VectorLayer({
      source: new VectorSource(),
      zIndex: 99,
    });

    this.armiesSource = new VectorSource();
    this.clusterSource = new Cluster({
      distance: 50, // Pixel distance between points before clustering
      source: this.armiesSource,
    });

    this.armiesLayer = new VectorLayer({
      source: this.clusterSource,
      style: this.getArmyClusterStyle.bind(this), // Apply a custom style for clustered armies
      zIndex: 100,
	  visible: false,
    });

    const defaultStyle = new Style({
      fill: new Fill({
        color: '#FAEBD7',
      }),
      stroke: new Stroke({
        color: '#404242',
        width: 2,
      }),
    });

    const dottedLineStyle = new Style({
      stroke: new Stroke({
        color: '#404242',
        width: 2,
        lineDash: [8, 4], // Dotted line pattern
      }),
    });

    this.provincesLayer = new VectorLayer({
      background: '#96c7eb',
      source: new VectorSource({
        url: 'map.geojson',
        format: new GeoJSON(),
      }),
      style: function (feature, resolution) {
        if (feature.get('line') === 'true') {
          return dottedLineStyle; // Apply dotted line style
        } else {
          return defaultStyle; // Apply default style
        }
      },
    });

    // Nation labels source and layer
    this.nationLabelsSource = new VectorSource();
    this.nationLabelsLayer = new VectorLayer({
		source: this.nationLabelsSource,
		style: this.getNationLabelStyle.bind(this), // Bind the style function
		zIndex: 200, // Ensure labels are on top
	  });

    this.map = new Map({
      layers: [this.provincesLayer, this.nationLabelsLayer, this.settlementsLayer, this.armiesLayer, this.movementArrowsLayer],
      target: 'map',
      view: new View({
        center: fromLonLat([-0.1276, 51.5074]),
        zoom: 3,
      }),
    });

    this.highlight = null;

    this.popupContainer = document.getElementById('popup');

    this.overlay = new Overlay({
      element: this.popupContainer,
      autoPan: true,
      autoPanAnimation: {
        duration: 5,
      },
    });
    this.map.addOverlay(this.overlay);

    this.map.on('click', this.onMapClick.bind(this));
    this.map.getView().on('change:resolution', this.onViewChangeResolution.bind(this));
	
	// Update labels when zoom level changes
	this.map.getView().on('change:resolution', () => {
		this.nationLabelsLayer.changed();
	  });
  }

  // In mapobj.js inside MapObj class
  drawMovementArrow(army, selectedDestination = null) {
	// Clear any existing arrows
	//this.movementArrowsLayer.getSource().clear();
  
	// Determine the destination
	let destination = selectedDestination || { id: army.destination, type: army.destination_type };
  
	// If no destination, return
	if (!destination || !destination.id || !destination.type) {
	  return;
	}
  
	// Get the coordinates of the army's current location
	let startCoord;
	if (army.location_type === 'province') {
	  const provinceFeature = this.provincesLayer.getSource().getFeatures().find(f => f.get('id') == army.location);
	  if (!provinceFeature) return;
	  startCoord = provinceFeature.getGeometry().getInteriorPoint().getCoordinates();
	} else if (army.location_type === 'settlement') {
	  const settlement = this.settlements[army.location];
	  if (!settlement) return;
	  const settlementFeature = this.settlementsLayer.getSource().getFeatureById(settlement.id);
	  if (!settlementFeature) return;
	  startCoord = settlementFeature.getGeometry().getCoordinates();
	}
  
	// Get the coordinates of the army's destination
	let endCoord;
	if (destination.type === 'province') {
	  const provinceFeature = this.provincesLayer.getSource().getFeatures().find(f => f.get('id') == destination.id);
	  if (!provinceFeature) return;
	  endCoord = provinceFeature.getGeometry().getInteriorPoint().getCoordinates();
	} else if (destination.type === 'settlement') {
	  const settlement = this.settlements[destination.id];
	  if (!settlement) return;
	  const settlementFeature = this.settlementsLayer.getSource().getFeatureById(settlement.id);
	  if (!settlementFeature) return;
	  endCoord = settlementFeature.getGeometry().getCoordinates();
	}
  
	// Create a LineString geometry between startCoord and endCoord
	const line = new Feature({
	  geometry: new LineString([startCoord, endCoord]),
	});

	line.set('army_id', army.id);
  
	// Style the line with an arrowhead
	line.setStyle(feature => {
	  const geometry = feature.getGeometry();
	  const styles = [
		// Line style
		new Style({
		  stroke: new Stroke({
			color: '#2e2e2e',
			width: 5,
		  }),
		}),
	  ];
  
	  // Arrowhead at the end
	  const coordinates = geometry.getCoordinates();
	  const end = coordinates[coordinates.length - 1];
	  const start = coordinates[coordinates.length - 2];
  
	  const dx = end[0] - start[0];
	  const dy = end[1] - start[1];
	  const rotation = Math.atan2(dy, dx);
  
	  // Adjust the rotation to align the arrowhead correctly
	  styles.push(new Style({
		geometry: new Point(end),
		image: new RegularShape({
		  fill: new Fill({ color: '#2e2e2e' }),
		  points: 3,       // Triangle shape
		  radius: 10,
		  rotation: -rotation + Math.PI / 2,  // Corrected rotation
		  angle: 0,        // No base angle rotation
		}),
	  }));
  
	  return styles;
	});
  
	// Add the line to the movementArrowsLayer
	this.movementArrowsLayer.getSource().addFeature(line);
  }
  
  
  
  

  getNationLabelStyle(feature, resolution) {
	const zoom = this.map.getView().getZoom();
  
	// Define font sizes for different zoom levels
	let fontSize;
  
	if (zoom >= 10) {
	  fontSize = 24;
	} else if (zoom >= 8) {
	  fontSize = 20;
	} else if (zoom >= 6) {
	  fontSize = 16;
	} else if (zoom >= 4) {
	  fontSize = 8;
	} else {
	  fontSize = 4;
	}

	if (feature.get('name').length > 10) {
		fontSize = fontSize * 0.8;
	}
  
	return new Style({
	  text: new Text({
		text: feature.get('name'),
		font: `bold ${fontSize}px sans-serif`,
		fill: new Fill({ color: '#000' }),
		stroke: new Stroke({ color: '#fff', width: 3 }),
		placement: 'point',
		textAlign: 'center',
	  }),
	});
  }
  

  onViewChangeResolution() {
    const zoom = this.map.getView().getZoom();
    var armyLayersVisible = zoom >= 5 && armyCheckbox.checked; // Adjust the zoom level threshold as needed
    this.armyLayers.forEach((layer) => layer.setVisible(armyLayersVisible));

	const nationLabelsVisible = (zoom < 5); // Adjust the zoom level threshold as needed
	this.nationLabelsLayer.setVisible(nationLabelsVisible);
  }

  promptFirstSettlementPlacement() {
    alert('Please place your settlement on the map.');
    this.settlementPlacementMode = true;

    // Create an image element to follow the cursor
    this.cursorImage = document.createElement('img');
    this.cursorImage.src = 'dot.png';
    this.cursorImage.style.position = 'absolute';
    this.cursorImage.style.pointerEvents = 'none';
    this.cursorImage.style.width = '32px';
    this.cursorImage.style.height = '32px';
    document.body.appendChild(this.cursorImage);

    // Bind the event handlers
    this.settlementPlacementHandler = this.handleSettlementPlacement.bind(this);
    this.updateCursorImagePositionHandler = this.updateCursorImagePosition.bind(this);

    this.map.on('click', this.settlementPlacementHandler);
    this.map.getViewport().addEventListener('pointermove', this.updateCursorImagePositionHandler);
  }

  updateCursorImagePosition(evt) {
    if (this.settlementPlacementMode && this.cursorImage) {
      // Get mouse position
      const mouseX = evt.clientX;
      const mouseY = evt.clientY;

      // Update image position
      this.cursorImage.style.left = mouseX - 16 + 'px';
      this.cursorImage.style.top = mouseY - 16 + 'px';
    }
  }

  async handleSettlementPlacement(evt) {
    if (this.settlementPlacementMode) {
      const coordinate = evt.coordinate;
      const lonLat = toLonLat(coordinate);

      // Get the province at the clicked location
      const pixel = this.map.getEventPixel(evt.originalEvent);
      const feature = this.map.forEachFeatureAtPixel(pixel, function (feature) {
        return feature;
      });

      if (feature && this.featureInLayer(feature, this.provincesLayer)) {
        const province_id = feature.get('id');

        // Remove the cursor image and event listeners
        document.body.removeChild(this.cursorImage);
        this.cursorImage = null;
        this.map.un('click', this.settlementPlacementHandler);
        this.map.getViewport().removeEventListener('pointermove', this.updateCursorImagePositionHandler);
        this.settlementPlacementMode = false;

        console.log(window.currentUser.id);

        const {
          data: { session },
        } = await supabase.auth.getSession();
        const accessToken = session.access_token;

        // Call the API to place the settlement
        fetch('/api/place_settlement', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            user_id: window.currentUser.id,
            coords: lonLat.join(','),
            name: window.currentUser.user_metadata.settlement_name,
            province_id: province_id,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            // Handle success
            console.log('Settlement placed:', data);

            // Reload settlements on the map
            this.loadSettlements(window.currentUser.id);
          })
          .catch((error) => {
            console.error('Error placing settlement:', error);
          });
      } else {
        alert('Please click on a province to place your settlement.');
      }
    }
  }

  featureInLayer(feature, layer) {
    return layer.getSource().getFeatures().includes(feature);
  }

  onMapClick(evt) {
    this.displayFeatureInfo(evt.pixel, evt.coordinate);
  }

  getArmyClusterStyle(feature) {
    // Get the number of features in the cluster
    const features = feature.get('features');
    const size = features.length;
    const totalArmySize = features.reduce((sum, feature) => {
      return sum + (feature.get('size') || 0); // Sum the sizes of all armies in the cluster
    }, 0);

    let style;

    if (size > 1) {
      // Style for clusters
      style = new Style({
        image: new Icon({
          src: 'army_icon2.png', // Path to your icon image
          anchor: [0.5, 1.3], // Anchor to place the icon above the circle (adjust as needed)
          scale: 0.13, // Scale the icon size if necessary
        }),
        text: new Text({
          text: totalArmySize.toString() + 'K', // Display the cluster size
          fill: new Fill({
            color: '#fff', // White text
          }),
          font: 'bold 16px sans-serif',
        }),
      });
    } else {
      // Style for individual armies (non-clustered)
      const army = feature.get('features')[0]; // Get the army feature
      const armySize = army.get('size'); // Assuming army has a size property

      style = new Style({
        image: new Icon({
          src: 'army_icon.png', // Path to your icon image
          anchor: [0.5, 1.3], // Anchor to place the icon above the circle (adjust as needed)
          scale: 0.13, // Scale the icon size if necessary
        }),
        text: new Text({
          text: armySize.toString() + 'K', // Display army size
          fill: new Fill({
            color: '#fff', // White text
          }),
          font: 'bold 16px sans-serif', // Font style for the text
        }),
      });
    }

    return style;
  }

  // Function to calculate small coordinate offsets
  getFeatureOffset(feature, size) {
    // Generate an offset angle and distance based on the number of overlapping features
    const angle = (Math.PI * 2 * Math.random()) / size; // Random angle based on number of features
    const distance = 500; // Small fixed distance to offset overlapping features

    const offsetX = Math.cos(angle) * distance;
    const offsetY = Math.sin(angle) * distance;

    return [offsetX, offsetY];
  }

  highlightProvince(province, options = {}) {
    const feature = this.provincesLayer.getSource().getFeatures().find((f) => f.get('id') == province.id);
    if (feature && feature.get('line') != 'true') {
      const highlightStyle = new Style({
        fill: options.isMoving
          ? new Fill({
              color: 'rgba(85, 189, 79)', // Different fill for selected state
            })
          : feature.getStyle()?.getFill() || new Fill({ color: '#FAEBD7' }),
        stroke: new Stroke({
          color: options.isSelected ? '#464747' : '#185419', // Different stroke color for selected state
          width: 4,
          lineDash: options.isSelected ? undefined : [8, 8],
        }),
        text: options.isMoving
          ? new Text({
              text: 'Move',
              font: 'bold 16px sans-serif',
            })
          : null,
        zIndex: 5,
      });

      feature.setStyle(highlightStyle);
    }
  }

  resetProvinceStyle(province) {
    const feature = this.provincesLayer.getSource().getFeatures().find((f) => f.get('id') == province.id);
    if (feature) {
      const highlightStyle = new Style({
        fill: province.color ? new Fill({ color: province.color }) : new Fill({ color: '#FAEBD7' }),
        stroke: new Stroke({
          color: '#404242', // Different stroke color for selected state
          width: 2,
          lineDash: undefined,
        }),
        text: null,
      });

      feature.setStyle(highlightStyle);
    }
  }

  enterMoveMode(army) {
    this.movingArmy = army; // Store the army that's being moved
    console.log('Select a location to move the army.');

    if (army.location_type == 'settlement') {
      const settlement = this.settlements[army.location];
      const province = this.provinces[settlement.province_id];
      this.highlightedProvinces.push(province.id);
      this.highlightProvince(province, { isMoving: true });
    } else {
      const province = this.provinces[army.location];
      const feature = this.provincesLayer.getSource().getFeatures().find((f) => f.get('id') == province.id);
      // set zindex of feature to 99
      feature.getStyle().setZIndex(99);

      const borderProvinces = this.provinces[army.location].borderProvinces;
      for (const borderProvince of borderProvinces) {
        this.highlightedProvinces.push(borderProvince);
        this.highlightProvince(this.provinces[borderProvince], { isMoving: true });
      }
    }

    // Highlight the province the army is currently in
    //this.highlightProvince(province);
  }

  displayFeatureInfo(pixel, coordinate) {
	const feature = this.map.forEachFeatureAtPixel(
	  pixel,
	  (feature, layer) => {
		console.log('Clicked feature:', feature);
		return feature;
	  },
	  {
		hitTolerance: 5, // Increase this value if necessary to make clicking easier
		layerFilter: (layer) => {
		  // Include army layers, provinces layer, and settlements layer in hit detection
		  return (
			this.armyLayers.includes(layer) ||
			layer === this.provincesLayer ||
			layer === this.settlementsLayer
		  );
		},
	  }
	);
  
	if (this.movingArmy) {
	  if (feature && this.featureInLayer(feature, this.provincesLayer)) {
		const province = this.provinces[feature.get('id')];
  
		// Draw movement arrow from current location to selected province
		this.drawMovementArrow(this.movingArmy, { id: province.id, type: 'province' });
  
		// Optionally, store the selected province in a variable
		this.selectedMoveDestination = {
		  location_id: province.id,
		  location_type: 'province',
		};
  
		// Move the army immediately
		this.movingArmy.moveArmy(province.id, 'province');
  
		// Reset move mode and clear highlights
		for (const provinceId of this.highlightedProvinces) {
		  this.resetProvinceStyle(this.provinces[provinceId]);
		}
		this.movingArmy = null;
		this.highlightedProvinces = [];
  
	  } else if (feature && this.featureInLayer(feature, this.settlementsLayer)) {
		const settlement = this.settlements[feature.get('id')];
  
		// Draw movement arrow from current location to selected settlement
		this.drawMovementArrow(this.movingArmy, { id: settlement.id, type: 'settlement' });
  
		// Optionally, store the selected settlement
		this.selectedMoveDestination = {
		  location_id: settlement.id,
		  location_type: 'settlement',
		};
  
		// Move the army immediately
		this.movingArmy.moveArmy(settlement.id, 'settlement');
  
		// Reset move mode and clear highlights
		for (const provinceId of this.highlightedProvinces) {
		  this.resetProvinceStyle(this.provinces[provinceId]);
		}
		this.highlightedProvinces = [];
		this.resetProvinceStyle(this.provinces[settlement.province_id]);
		this.movingArmy = null;
  
	  } else {
		alert('Please select a valid province or settlement.');
	  }
	} else {
    if (
      feature &&
      this.featureInLayer(feature, this.provincesLayer) &&
      feature.get('line') != 'true'
    ) {
      const province = this.provinces[feature.get('id')];
      if (feature !== this.highlight) {
        if (this.highlight) {
          this.resetProvinceStyle(this.provinces[this.highlight.get('id')]);
        }
        this.highlight = feature;
        this.highlightProvince(province, { isSelected: true });
      } else {
        // Deselect if the same province is clicked again
        this.resetProvinceStyle(province);
        this.highlight = null;
      }
      this.map.render();
      showPopUp('Province', province, coordinate);
      if (this.RightPanel == 'settlement') {
        closeRightPanel();
      }
      leftPanelSetInfo(province);
      rightPanelSetInfo('province', province);
    } else if (feature && this.featureInLayer(feature, this.settlementsLayer)) {
      const settlement = this.settlements[feature.get('id')];
      showPopUp('Settlement', settlement, coordinate);
      if (this.RightPanel == 'province') {
        closeRightPanel();
      }
      rightPanelSetInfo('settlement', settlement);
	  } else if (feature && feature.get('features')) {
		// This is an army cluster (army icon)
		const featuresInCluster = feature.get('features');
  
		// Check if any of the features (armies) belong to the user
		const userArmies = featuresInCluster.filter(
		  (f) => f.get('user_id') == window.currentUser.id
		);
  
		if (userArmies.length > 0) {
		  // The user has at least one army in the cluster
		  const armyFeature = userArmies[0]; // You can handle multiple armies if needed
		  const location = armyFeature.get('location');
		  const location_type = armyFeature.get('location_type');

		  // Get the army IDs from the features
		  const armyIds = userArmies.map(f => f.get('army_id'));

		  // Get the army objects from this.armies
		  const selectedArmies = armyIds.map(id => this.armies[id]);

  
		  // Depending on location_type, get the province or settlement
		  if (location_type == 'settlement') {
			const settlement = this.settlements[location];
			rightPanelSetInfo('settlement', settlement, selectedArmies);
		  } else if (location_type == 'province') {
			const province = this.provinces[location];
			rightPanelSetInfo('province', province, selectedArmies);
			rightSlidingPanel.classList.add('open');
			panelShowInfo(rightSlidingPanel, 'province');
			closePopUp();
		  }
  
  
		  // Close any open popups
		  closePopUp();
		} else {
		  // No armies in the cluster belong to the user
		  console.log('No user armies in this cluster.');
		}
	  } else {
		// Clicked outside of features or on features not handled
		this.overlay.setPosition(undefined);
		if (this.highlight) {
		  this.resetProvinceStyle(this.provinces[this.highlight.get('id')]);
		  this.highlight = null;
		}
		closePopUp();
		closeLeftPanel();
		closeRightPanel();
    this.movementArrowsLayer.getSource().clear();
	  }
	}
  }
  
  

  toggleArmyLayers(visible) {
    this.armyLayers.forEach((layer) => layer.setVisible(visible));
  }

  setMapCenter() {
    const userSettlementKey = Object.keys(this.settlements).find(
      (key) => this.settlements[key].user_id == window.currentUser.id
    );

    if (userSettlementKey) {
      const userCenter = this.settlements[userSettlementKey].coords;

      const view = this.map.getView();
      view.setCenter(fromLonLat(userCenter));
      view.setZoom(6);
    }
  }

  // Fetch and draw settlements as features
  async loadSettlements() {
    const response = await fetch('api/get_settlements');
    const settlementData = await response.json();
    this.settlements = {};

    settlementData.forEach((settlement) => {
      const workingSettlement = new Settlement(settlement, this);
      this.settlements[settlement.id] = workingSettlement;
      workingSettlement.drawIcon();
    });

    if (!window.currentUser) {
      return;
    }

    // Check if userId matches any settlement user_id
    const userSettlement = settlementData.find((settlement) => settlement.user_id == window.currentUser.id);

    if (!userSettlement) {
      this.firstSettlementPlacementRequired = true;
      this.promptFirstSettlementPlacement();
    } else {
      await window.currentUser;
      console.log('code ran');
      console.log(window.currentUser);
      console.log(settlementData);
      this.setMapCenter();
    }
  }

  // Fetch provinces and load the data into existing province features
  async loadProvinces() {
    const response = await fetch('api/get_provinces');
    const provinceData = await response.json();
    this.provinces = {};

    provinceData.forEach((province) => {
      const workingProvince = new Province(province, this);
      this.provinces[province.id] = workingProvince;
      workingProvince.loadProvinceData();

      // Ensure nation_id and nation_name are set
      workingProvince.nation_id = province.nation_id;
      workingProvince.nation_name = province.nation_name;
    });

    // Update nation labels after loading provinces
    this.updateNationLabels();
  }

  // Inside loadArmies function in mapobj.js
async loadArmies() {
	const response = await fetch('api/get_armies');
	const armyData = await response.json();
	this.armies = {};
  
	// Group armies by their nation_id
	const armiesByNation = armyData.reduce((acc, army) => {
	  if (army.location_type === 'province') {
		// Initialize array for each nation if not already initialized
		if (!acc[army.nation_id]) {
		  acc[army.nation_id] = [];
		}
		acc[army.nation_id].push(army);
	  }
	  return acc;
	}, {});
  
	// Clear existing army layers from the map and reset armyLayers array
	this.armyLayers.forEach((layer) => this.map.removeLayer(layer));
	this.armyLayers = [];
  
	// Create a cluster and layer for each nation
	Object.keys(armiesByNation).forEach((nationId) => {
	  // Create a new source and cluster source for each nation
	  const armySource = new VectorSource();
  
	  const nationClusterSource = new Cluster({
		distance: 50, // Distance for clustering
		source: armySource,
	  });
  
	  // Create army features for each army belonging to this nation
	  const armyFeatures = armiesByNation[nationId]
		.map((army) => {
		  const province = this.provinces[army.location]; // Get the province by location
  
		  if (!province) {
			console.warn(`Province not found for army in province ${army.location}`);
			return null;
		  }
  
		  const provinceFeature = this.provincesLayer
			.getSource()
			.getFeatures()
			.find((f) => f.get('id') == province.id);
		  if (!provinceFeature) {
			console.warn(`Feature not found for province ${province.id}`);
			return null;
		  }
  
		  const provinceCenter = provinceFeature.getGeometry().getInteriorPoint().getCoordinates();
  
		  // Create a feature for this army
		  const armyFeature = new Feature({
			geometry: new Point(provinceCenter),
			size: army.size,
			nation_id: army.nation_id, // Nation ID
			nation_color: army.nation_color, // Nation color
		  });
  
		  // Set additional properties
		  armyFeature.set('user_id', army.user_id);
		  armyFeature.set('location', army.location);
		  armyFeature.set('location_type', army.location_type);
		  armyFeature.set('army_id', army.id); // Add army_id to identify the army
  
		  return armyFeature;
		})
		.filter((feature) => feature !== null);
  
	  // Add features to the respective source
	  armySource.addFeatures(armyFeatures);
  
	  // Create a new layer for this nation's cluster
	  const armyLayer = new VectorLayer({
		source: nationClusterSource,
		style: this.getArmyClusterStyle.bind(this),
		zIndex: 100,
		visible: true, // Ensure the layer is visible
		renderMode: 'vector', // Enable hit detection on clusters
	  });
	  this.armyLayers.push(armyLayer);
  
	  // Add the layer to the map
	  this.map.addLayer(armyLayer);
	});
  
	// Save army data for later use
	armyData.forEach((army) => {
	  const workingArmy = new Army(army, this);
	  this.armies[army.id] = workingArmy;
	});
  }
  

  // Update nation labels based on current province ownership
  updateNationLabels() {
	// Clear previous labels
	this.nationLabelsSource.clear();
  
	let nations = {};
  
	// Group provinces by nation
	for (let provinceId in this.provinces) {
	  const province = this.provinces[provinceId];
	  const nation_id = province.nation_id;
	  const nation_name = province.nation_name;
  
	  if (!nation_id) continue; // Skip provinces with no nation
  
	  if (!nations[nation_id]) {
		nations[nation_id] = {
		  name: nation_name,
		  provinces: [],
		};
	  }
	  nations[nation_id].provinces.push(province);
	}
  
	// For each nation, create label
	for (let nation_id in nations) {
	  const nation = nations[nation_id];
  
	  // Collect province geometries and centroids
	  let geometries = [];
	  let provinceCentroids = [];
  
	  for (let province of nation.provinces) {
		// Get feature from provincesLayer
		const feature = this.provincesLayer
		  .getSource()
		  .getFeatures()
		  .find((f) => f.get('id') == province.id);
		if (feature) {
		  const geometry = feature.getGeometry();
		  geometries.push(geometry);
  
		  // Calculate centroid of the province
		  const centroid = geometry.getInteriorPoint().getCoordinates();
		  provinceCentroids.push(centroid);
		}
	  }
  
	  // Compute the centroid of the nation based on province centroids
	  let sumX = 0;
	  let sumY = 0;
	  let numCentroids = provinceCentroids.length;
  
	  for (let coord of provinceCentroids) {
		sumX += coord[0];
		sumY += coord[1];
	  }
  
	  const nationCentroid = [sumX / numCentroids, sumY / numCentroids];
  
	  // Find the province whose centroid is closest to the nation's centroid
	  let minDistance = Infinity;
	  let centerMostProvince = null;
  
	  for (let province of nation.provinces) {
		const feature = this.provincesLayer
		  .getSource()
		  .getFeatures()
		  .find((f) => f.get('id') == province.id);
		if (feature) {
		  const geometry = feature.getGeometry();
		  const centroid = geometry.getInteriorPoint().getCoordinates();
  
		  // Calculate squared distance to avoid unnecessary square root computation
		  const dx = centroid[0] - nationCentroid[0];
		  const dy = centroid[1] - nationCentroid[1];
		  const distanceSq = dx * dx + dy * dy;
  
		  if (distanceSq < minDistance) {
			minDistance = distanceSq;
			centerMostProvince = feature;
		  }
		}
	  }
  
	  // Use the interior point of the center most province for the label
	  if (centerMostProvince) {
		const labelPointCoord = centerMostProvince.getGeometry().getInteriorPoint().getCoordinates();
  
		// Create feature with Point geometry at labelPointCoord
		const labelFeature = new Feature({
		  geometry: new Point(labelPointCoord),
		  name: nation.name,
		});
  
		// Add to nationLabelsSource
		this.nationLabelsSource.addFeature(labelFeature);
	  }
	}
  
	// Render the map
	this.map.render();
  }
  
  
  
}
