import './ui/leftpanel.js';
import './ui/rightpanel.js';
import { supabase } from './supabaseClient.js';

export function panelShowInfo(panel, type) {
  if (type == 'province') {
    panel.querySelector('#right-panel-province').style.display = 'block';
    panel.querySelector('#right-panel-settlement').style.display = 'none';
  } else if (type == 'settlement') {
    panel.querySelector('#right-panel-province').style.display = 'none';
    panel.querySelector('#right-panel-settlement').style.display = 'block';
  }
}

async function logout() {
	await supabase.auth.signOut();
	window.currentUser = null;
	window.location.href = '/login.html';
  }
  

// Attach the logout function to the window object
window.logout = logout;

const settlementCheckbox = document.getElementById('settlement-checkbox');
const armyCheckbox = document.getElementById('army-checkbox');

// Toggle settlement layer visibility
settlementCheckbox.addEventListener('change', (event) => {
  window.map.settlementsLayer.setVisible(event.target.checked);
});

// Toggle army layer visibility
armyCheckbox.addEventListener('change', (event) => {
  window.map.toggleArmyLayers(event.target.checked); // Toggle all army layers
});

// Clock code
function getNextThirdHourTime() {
  const now = new Date();
  const currentHourUTC = now.getUTCHours();

  // Calculate the next 3rd hour in UTC
  let nextThirdHourUTC = currentHourUTC + (3 - (currentHourUTC % 3));
  if (currentHourUTC % 3 === 0 && now.getUTCMinutes() === 0 && now.getUTCSeconds() === 0) {
    nextThirdHourUTC = currentHourUTC;
  }

  // Handle rollover to the next day
  if (nextThirdHourUTC >= 24) {
    nextThirdHourUTC = nextThirdHourUTC % 24;
  }

  const addDay = nextThirdHourUTC <= currentHourUTC ? 1 : 0;

  // Create the next tick time in UTC
  const nextTick = new Date(Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate() + addDay,
    nextThirdHourUTC,
    0,
    0,
    0
  ));

  return nextTick;
}

function updateClock() {
  const now = new Date();
  const nowUTC = Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds(),
    now.getUTCMilliseconds()
  );

  const nextTick = getNextThirdHourTime();
  const timeRemaining = nextTick.getTime() - nowUTC; // in milliseconds

  // If timeRemaining is negative, add 24 hours (handles potential edge cases)
  if (timeRemaining < 0) {
    timeRemaining += 24 * 3600 * 1000;
  }

  const totalSeconds = Math.floor(timeRemaining / 1000);

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  // Format the time as HH:MM:SS
  const timeString = `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;

  // Update the clock on the page
  const clockElement = document.getElementById('clock-time');
  if (clockElement) {
    clockElement.textContent = `${timeString}`;
  }
}

function padZero(num) {
  return num < 10 ? '0' + num : num;
}

// Update the clock every second
setInterval(updateClock, 1000);

// Initial call
updateClock();




async function getUser() {
  const {
    data: { user },
    error,
  } = await supabase.auth.getUser();

  if (user) {
    document.getElementById('user-display').textContent = user.user_metadata.display_name;
    document.querySelector('.login-button').style.display = 'none';
    document.querySelector('.signup-button').style.display = 'none';
    window.currentUser = user;
    return user;
  } else {
    document.querySelector('.logout-button').style.display = 'none';
    window.currentUser = null;
  }
}

getUser();
