import { loadArmies, rightPanelSetInfo } from "../ui/rightpanel";
import { supabase } from '../supabaseClient.js';

export default class Army {
	constructor(data, mapObj) {
		this.id = data.id;
		this.user_id = data.user_id;
		this.size = data.size;
		this.location = data.location;
		this.location_type = data.location_type;
		this.mapObj = mapObj;
		this.destination = data.destination;
		this.destination_type = data.destination_type;
		this.allegiance = data.allegiance;
		this.settlement_name = data.settlement_name;
		this.loyal = data.loyal;
		this.nation_id = data.nation_id;
		this.nation_color = data.nation_color;
	}

	async moveArmy(destination, destination_type) {
		const { data: { session } } = await supabase.auth.getSession();
		const accessToken = session.access_token;

		fetch('/api/move_army', {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json',
			  'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify({ army_id: this.id, location_id: this.location, location_type: this.location_type, destination: destination, destination_type: destination_type })
		  })
			.then(response => response.json())
			.then(data => {
				if (data.message == 'success') {
					console.log("Army moved successfully");
					this.destination = destination;
					this.destination_type = destination_type;

					console.log(this.destination_type);

					if (this.location_type == 'settlement') {
						loadArmies(window.map.settlements[this.location], movedArmies = true);
					}
					else {
						loadArmies(window.map.provinces[this.location], movedArmies = true);
					}

				}
			});
	}
}