// Left panel for nations
const nationButton = document.getElementById('nation-button');
const leftSlidingPanel = document.getElementById('left-sliding-panel');

// Touch variables
let leftPanelStartX = 0;
let leftPanelCurrentX = 0;
let leftPanelTouching = false;

// Add touch event listeners
leftSlidingPanel.addEventListener('touchstart', onLeftPanelTouchStart);
leftSlidingPanel.addEventListener('touchmove', onLeftPanelTouchMove);
leftSlidingPanel.addEventListener('touchend', onLeftPanelTouchEnd);

function onLeftPanelTouchStart(e) {
  leftPanelStartX = e.touches[0].clientX;
  leftPanelTouching = true;
}

function onLeftPanelTouchMove(e) {
  if (!leftPanelTouching) return;
  leftPanelCurrentX = e.touches[0].clientX;
  const translateX = Math.min(0, leftPanelCurrentX - leftPanelStartX);
  leftSlidingPanel.style.transform = `translateX(${translateX}px)`;
}

function onLeftPanelTouchEnd(e) {
  leftPanelTouching = false;
  const swipeDistance = leftPanelCurrentX - leftPanelStartX;
  if (swipeDistance < -50) {
    // User swiped left enough to close the panel
    closeLeftPanel();
  } else {
    // Reset the panel position
    leftSlidingPanel.style.transform = '';
  }
}

nationButton.addEventListener('click', function () {
  leftSlidingPanel.classList.toggle('open');
  leftSlidingPanel.style.transform = ''; // Reset transform when opening
});

const leftPanelCloseButton = document.getElementById('left-panel-close-button');

leftPanelCloseButton.addEventListener('click', closeLeftPanel);

export function closeLeftPanel() {
  leftSlidingPanel.classList.remove('open');
  leftSlidingPanel.style.transform = ''; // Reset transform when closing
}

export function leftPanelSetInfo(obj) {
  document.getElementById('lp-nation-name').innerHTML = obj.nation_name;
  document.getElementById('lp-nation-owner').innerHTML = obj.nation_user;
  document.getElementById('lp-nation-manpower').innerHTML = obj.nation_manpower;
}
