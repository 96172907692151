import MapObj from './src/js/classes/mapobj.js'
import './src/js/index.js'

const map = new MapObj()

async function loadMapData() {
  await map.loadSettlements()
  await map.loadProvinces()
  await map.loadArmies()
}

// Load map data test
Promise.all([
  loadMapData()
]).then(() => {
  // Trigger the fade-out by adding the 'hidden' class
  const loadingOverlay = document.getElementById('loading-overlay');
  loadingOverlay.classList.add('hidden');
  
  // Remove the overlay entirely after the transition (optional)
  setTimeout(() => {
    loadingOverlay.style.display = 'none';
  }, 1000); // Match this time with the transition duration
});

window.map = map;
