const popupContainer = document.getElementById('popup');
const popupProvinceContainer = document.getElementById('popup-province');
const popupProvince = document.getElementById('popup-province-id');
const popupProvinceOwner = document.getElementById('popup-province-owner-id');
const popupCloser = document.getElementById('popup-closer');
const popupSettlementContainer = document.getElementById('popup-settlement');
const popupSettlement = document.getElementById('popup-settlement-id')
const popupSettlementOwner = document.getElementById('popup-settlement-owner-id');

function popupShowInfo(type) {
	if (type === 'province') {
		popupProvinceContainer.style.display = 'block';
		popupSettlementContainer.style.display = 'none';
	} 
	else if (type === 'settlement') {
		popupProvinceContainer.style.display = 'none';
		popupSettlementContainer.style.display = 'block';
	}
}

export function showPopUp(type, data, coordinate) {
	if (type == 'Settlement') {
		popupSettlement.innerHTML = data.id;
		popupSettlementOwner.innerHTML = data.user_id;
		popupContainer.classList.add('open');
		popupShowInfo('settlement')
		window.map.overlay.setPosition(coordinate);
	}
	else if (type == 'Province') {
		popupProvince.innerHTML = data.id;
		popupProvinceOwner.innerHTML = data.nation_id;
		popupContainer.classList.add('open');
		popupShowInfo('province')
		window.map.overlay.setPosition(coordinate);
	}
	
}

popupCloser.onclick = () => {
	window.map.overlay.setPosition(undefined);
	popupCloser.blur();
	return false;
};

export function closePopUp() {
	popupContainer.classList.remove('open');
	window.map.overlay.setPosition(undefined);
	popupCloser.blur();
	return false;
}


